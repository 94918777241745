var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShown
    ? _c("div", { staticClass: "splash" }, [
        _c(
          "div",
          {
            staticClass: "close-button",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          },
          [_vm._v("×")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "carrier" }, [
          _c("table", [
            _c("tbody", [
              _vm.projectId === "8855617f-8998-47d9-ae8e-cc75d79293a8"
                ? _c("tr", [_vm._m(0), _vm._v(" "), _vm._m(1)])
                : _vm._e(),
              _vm._v(" "),
              _vm.projectId === "f465e185-0c4d-4ad2-a565-3e7575a97f0b"
                ? _c("tr", [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("td", { attrs: { width: "50%" } }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.projectId === "8855617f-8998-47d9-ae8e-cc75d79293a8"
                ? _c("tr", [_vm._m(3)])
                : _vm._e(),
              _vm._v(" "),
              _vm.projectId !== "8855617f-8998-47d9-ae8e-cc75d79293a8"
                ? _c("tr", [
                    _c("td", { staticClass: "info", attrs: { colspan: "2" } }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.splashHTML) }
                      })
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "manage" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "p-check",
                {
                  staticClass: "p-icon p-bigger p-smooth",
                  model: {
                    value: _vm.neverShowAgain,
                    callback: function($$v) {
                      _vm.neverShowAgain = $$v
                    },
                    expression: "neverShowAgain"
                  }
                },
                [
                  _c("i", {
                    staticClass: "icon fa fa-check",
                    attrs: { slot: "extra" },
                    slot: "extra"
                  }),
                  _vm._v(
                    "\n        Больше не показывать информационное окно\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _c("i", { staticClass: "icofont icofont-ui-close" }),
                _vm._v(" Закрыть информационное окно\n      ")
              ]
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          height: "100px",
          src: "/img/minobr_logo.png",
          title: "МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ",
          alt: "МИНИСТЕРСТВО ПРОСВЕЩЕНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ"
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "minobr-logo-text" }, [
        _c("br"),
        _vm._v("\n              МИНИСТЕРСТВО"),
        _c("br"),
        _vm._v("\n              ПРОСВЕЩЕНИЯ"),
        _c("br"),
        _vm._v("\n              РОССИЙСКОЙ ФЕДЕРАЦИИ\n            ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "logo" }, [
      _c("img", {
        attrs: {
          height: "150px",
          src: "/img/child_protect_logo.png",
          title: "ЦЕНТР ЗАЩИТЫ ПРАВ И ИНТЕРЕСОВ ДЕТЕЙ",
          alt: "ЦЕНТР ЗАЩИТЫ ПРАВ И ИНТЕРЕСОВ ДЕТЕЙ"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { width: "50%" } }, [
      _c("img", {
        attrs: {
          height: "200px",
          src: "/img/logo-fond.png",
          title: "Фонд",
          alt: "Фонд"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "info", attrs: { colspan: "2" } }, [
      _c("p", [
        _vm._v(
          '\n              Департамент государственной политики в сфере защиты прав детей в рамках федерального проекта "Современная школа" национального проекта "Образование" (поддержка образования для детей с\n              ограниченными возможностями здоровья) проводит тестирование компетенций преподавателей предметной области "Технология", заместителей директора, педагогов-психологов,\n              учителей-дефектологов,\n              учителей-логопедов образовательных организаций, осуществляющих образовательную деятельность по адаптированными основными общеобразовательными программам — участников проекта\n              "Современная\n              школа".\n            '
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          '\n              Тестирование организовано ФГБНУ "Центр защиты прав и интересов детей" и будет проводиться с 1 по 14 июня 2019 года.\n            '
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              Тестирование проводится с целью изучения компетенции специалистов для дальнейшего анализа и разработки программ курсов повышения квалификации.\n            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }