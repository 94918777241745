import a, { AxiosPromise } from 'axios'
import API from 'api'
import TestCategory from 'model/TestCategory'
import Test from 'model/TestData'
import CountryRegion from '../model/CountryRegion'

export default class FiltersAPI {
  public static getTestCategories (): AxiosPromise<TestCategory[]> {
    return this.getFilterData('testCategories')
  }

  public static getTests (): AxiosPromise<Test[]> {
    return this.getFilterData('tests')
  }

  public static getOrgNames (region: CountryRegion): AxiosPromise<string[]> {
    return a.post(API.api2 + '/filters/orgnames', { ...region })
  }

  public static getRegions (): AxiosPromise<CountryRegion[]> {
    return this.getFilterData('regions')
  }

  private static getFilterData (entityName): AxiosPromise<any[]> {
    return a.get(API.api2 + '/filters/' + entityName.toLowerCase())
  }
}
