import { Component, Vue } from 'vue-property-decorator'
import TableResults from 'part/table-results'
import Tests from 'part/tests'
import ResultProgressBar from 'component/result-progress-bar'

import bTable from 'bootstrap-vue/es/components/table/table'

import a from 'axios'
import moment from 'moment'
import API from 'api/API'

@Component({
  template: require('template/diagnostika.html'),
  components: { TableResults, ResultProgressBar, bTable }
})
export default class Diagnostika extends Vue {
  pageTitle: string = 'Моя диагностика'

  public lastTestResults: any[] = []

  displayLoading: boolean = false
  unfinishedTestTracksCount: number = 0

  tableSortBy: string = 'result'
  tableSortDesc: boolean = true
  tableFields: any[] = [
    {
      key: 'categoryName',
      label: 'Категория',
      sortable: true
    },
    {
      key: 'testName',
      label: 'Тест',
      sortable: true
    },
    {
      key: 'endDate',
      label: 'Время завершения',
      sortable: true
    },
    {
      key: 'resultValue',
      label: 'Результат',
      sortable: true
    },
    {
      key: 'resultId',
      label: ' ',
      sortable: true
    }
  ]

  public mm = moment

  mounted () {
    this.ifAnyUnfinishedTestTracksLeft()
    let username = localStorage.getItem('username')
    if (username) {
      this.loadLastTestResults(username)
    }
  }

  private loadLastTestResults (username: string) {
    const self = this
    this.displayLoading = true
    a.get(API.api + '/test/result/last')
      .then(r => {
        this.lastTestResults = r.data
        this.displayLoading = false
      })
      .catch(e => console.error(e))
  }

  private ifAnyUnfinishedTestTracksLeft () {
    API.getUnfinishedTestTracksCount().then(r => this.unfinishedTestTracksCount = r.data)
  }

  get noTestsYet () {
    return !(Array.isArray(this.lastTestResults) && this.lastTestResults.length > 0)
  }
}
