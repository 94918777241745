
import { Component, Vue } from 'vue-property-decorator'
import API from 'api'

@Component({
  components: {}
})
export default class Splash extends Vue {
  projectId: string = ''
  splashHTML: string = ''
  isShown: boolean = true
  neverShowAgain: boolean = false

  mounted () {
    this.projectId = JSON.parse(Vue.localStorage.get('project.id'))
    console.log(this.projectId)
    API.getTestProjectSplash(this.projectId).then(r => this.splashHTML = (r.data) ? r.data.splashHTML : '')
    let savedNeverShowAgain = (Vue.localStorage.get('splash.neverShowAgain') === 'true')
    console.log('savedNeverShowAgain', savedNeverShowAgain)
    if (savedNeverShowAgain != null) {
      this.neverShowAgain = savedNeverShowAgain
    } else {
      this.neverShowAgain = false
    }
    if (this.neverShowAgain) {
      this.isShown = false
    }
  }

  close () {
    this.isShown = false
    Vue.localStorage.set('splash.neverShowAgain', this.neverShowAgain)
  }
}
