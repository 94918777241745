import Vue from 'vue'
import { createRouter } from './router'
import { ContentLoader } from 'vue-content-loader'
import axios from 'axios'
import { OAuth2Response } from 'page/login'

function start () {
  axios.interceptors.request.use(config => {
    let oauthData = JSON.parse(Vue.localStorage.get('oauth')) as OAuth2Response
    if (oauthData) {
      config.headers = Object.assign(config.headers, { 'Authorization': 'Bearer ' + oauthData.accessToken })
    }
    return config
  })
  // tslint:disable-next-line:no-unused-expression
  new Vue({
    el: '#app-main',
    router: createRouter(),
    components: {
      ContentLoader
    }
  })
}

start()
