import { Component, Vue } from 'vue-property-decorator'
import API from 'api/API'

@Component({
  template: require('template/admin.html'),
  components: {}
})
export default class Admin extends Vue {
  iframeSrc: string = API.admin
}
