import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Site from 'section/site.vue'

import About from 'page/about'
import Admin from 'page/admin'
import Appointments from 'page/appointments'
import Contacts from 'page/contacts'
import Diagnostika from 'page/diagnostika'
import Disclaimer from 'page/disclaimer'
import Index from 'page/index'
import Login from 'page/login'
import Register from 'page/register'
import Profile from 'page/profile'

const Overview = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "help" */ 'page/overview')
const OverviewHelp = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "help" */ 'page/overview-help')
const OverviewCourses = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "help" */ 'page/overview-courses')

const Answers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/answers')
const Codifiers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/codifiers')
const MethodData = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/method-data')
const MethodDataQuestion = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/method-data-question')
const MethodPanel = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/method-panel')
const RatingTests = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/rating-tests')
const RatingCodifiers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/rating-codifiers')
const ReviewList = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/review-list')
const TestsDownload = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "method" */ 'page/method/tests-download')

const AnalyticIndex = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/analytic-index')
const MembersData = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/members-data')
const RateRegions = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/rate-regions')
const RateDistricts = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/rate-districts')
const RateSchools = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/rate-schools')
const RateTeachers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/rate-teachers')
const RateStudents = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/rate-students')
const CourseAppointmentsSimple = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/course-appointments-simple')
const DiagramsList = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/diagrams-list')
const Diagrams = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "analytic" */ 'page/analytic/diagrams')

const Test = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "test" */ 'page/test')
const TestResult = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "test" */ 'page/test-result')
const Tests = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "test" */ 'page/tests')
const TestTracks = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "test" */ 'page/test-tracks')

const ProjectsList = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin" */ 'page/administration/projects-list.vue')
const ProjectForm = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin" */ 'page/administration/projects-form.vue')
const ProjectRoles = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin" */ 'page/administration/projects-roles.vue')
const ProjectUsers = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin" */ 'page/administration/projects-users.vue')
const ProjectTests = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin" */ 'page/administration/projects-tests.vue')

const ConstructorCategories = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "constr" */ 'page/constructor/constructor-categories')
const ConstructorEditor = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "constr" */ 'page/constructor/constructor-editor')

const Courses = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/courses')
const Homework = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/homework')
const HomeworkCheck = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/homework-check')
const CoursePage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/course-page')
const CertificatePage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/cert-page')
const Certificates = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/certs')
const Video = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "edu" */ 'component/education/video')

import Auth from 'api/Auth'
import User from 'model/User'

Vue.use(VueRouter)

export const createRoutes: () => RouteConfig[] = () => [
  {
    path: '/enter',
    component: Login,
    meta: {
      openPage: true
    }
  },
  {
    path: '/',
    component: Site,
    children: [
      {
        path: '/',
        component: Index
      },
      {
        path: '/index',
        component: Index
      },
      {
        path: '/about',
        component: About
      },
      {
        path: '/adm',
        component: Admin
      },
      {
        path: '/appointments',
        component: Appointments
      },
      {
        path: '/projects',
        component: ProjectsList
      },
      {
        path: '/projects/project/:testProjectId',
        component: ProjectForm,
        props: true
      },
      {
        path: '/projects/project/:testProjectId/roles',
        component: ProjectRoles,
        props: true
      },
      {
        path: '/projects/project/:testProjectId/users',
        component: ProjectUsers,
        props: true
      },
      {
        path: '/projects/project/:testProjectId/tests',
        component: ProjectTests,
        props: true
      },
      {
        path: '/projects/project',
        component: ProjectForm
      },
      {
        path: '/constructor-categories',
        component: ConstructorCategories
      },
      {
        path: '/constructor/new/category/:categoryId',
        component: ConstructorEditor,
        props: true
      },
      {
        path: '/constructor/edit/:id',
        component: ConstructorEditor,
        props: true
      },
      {
        path: '/contacts',
        component: Contacts
      },
      {
        path: '/diagnostika',
        component: Diagnostika
      },
      {
        path: '/disclaimer',
        component: Disclaimer
      },
      {
        path: '/overview',
        component: Overview
      },
      {
        path: '/overview/courses',
        component: OverviewCourses
      },
      {
        path: '/overview/help',
        component: OverviewHelp
      },
      {
        path: '/profile',
        component: Profile
      },
      {
        path: '/register',
        component: Register
      },

      {
        path: '/method/data/:id',
        component: MethodData,
        props: true
      },
      {
        path: '/method/data-question/:testId/:id',
        component: MethodDataQuestion,
        props: true
      },
      {
        path: '/method/panel',
        component: MethodPanel
      },
      {
        path: '/method/codifiers',
        component: Codifiers
      },
      {
        path: '/method/answers',
        component: Answers
      },
      {
        path: '/method/rating/tests',
        component: RatingTests
      },
      {
        path: '/method/rating/codifiers',
        component: RatingCodifiers
      },
      {
        path: '/method/reviews',
        component: ReviewList
      },
      {
        path: '/method/tests-download',
        component: TestsDownload
      },

      {
        path: '/analytic/index',
        component: AnalyticIndex
      },
      {
        path: '/analytic/members-data',
        component: MembersData
      },
      {
        path: '/analytic/rate-regions',
        component: RateRegions
      },
      {
        path: '/analytic/rate-districts',
        component: RateDistricts
      },
      {
        path: '/analytic/rate-students',
        component: RateStudents
      },
      {
        path: '/analytic/rate-schools',
        component: RateSchools
      },
      {
        path: '/analytic/rate-teachers',
        component: RateTeachers
      },
      {
        path: '/analytic/diagrams-list',
        component: DiagramsList
      },
      {
        path: '/analytic/diagrams',
        component: Diagrams
      },
      {
        path: '/analytic/appointments',
        component: CourseAppointmentsSimple
      },
      {
        path: '/analytic/appointments/step/:currentPageProp',
        component: CourseAppointmentsSimple,
        props: true
      },
      {
        path: '/test/:id',
        component: Test,
        props: true
      },
      {
        path: '/test-result/:id',
        component: TestResult,
        props: true
      },
      {
        path: '/test-result/:id/diagnostics',
        component: TestResult,
        props: (route) => { return { id: route.params.id, hideReview: true, showRecommendedCourses: false, showGotoRecommended: true } }
      },
      {
        path: '/test-result/:id/recommendations',
        component: TestResult,
        props: (route) => { return { id: route.params.id, hideReview: true, showRecommendedCourses: true } }
      },
      {
        path: '/tests',
        component: Tests
      },
      {
        path: '/tracks',
        component: TestTracks
      },
      {
        path: '/courses',
        component: Courses,
        props: { catalog: true, selection: false, my: false }
      },
      {
        path: '/courses/my',
        component: Courses,
        props: { catalog: false, selection: false, my: true }
      },
      {
        path: '/courses/select',
        component: Courses,
        props: { catalog: false, selection: true, my: false }
      },
      {
		    path: '/courses/:id',
		    component: CoursePage
      },
      {
        path: '/cert/:id',
        component: CertificatePage
      },
      {
        path: '/certs',
        component: Certificates
      },
      {
        path: '/courses/:id/catalog',
        component: CoursePage,
        props: (route) => { return { id: route.params.id, showAddBackButtons: true, returnToMessage: 'Вернуться к каталогу курсов', allowToLearn: false } }
      },
      {
        path: '/courses/:id/learn',
        component: CoursePage,
        props: (route) => { return { id: route.params.id, showAddBackButtons: false, returnToMessage: null, allowToLearn: true } }
      },
      {
        path: '/courses/:id/diagnostic',
        component: CoursePage,
        props: (route) => { return { id: route.params.id, showAddBackButtons: true, returnToMessage: 'Вернуться к списку рекомендованных курсов', allowToLearn: false } }
      },
      {
	    path: '/videos/:id',
	    component: Video
      },
      {
        path: '/homeworks/:id',
        component: Homework
      },
      {
        path: '/homework-checks/:id',
        component: HomeworkCheck
      }
    ]
  }
]

export const createRouter = () => {
  let router = new VueRouter({
    mode: 'history',
    routes: createRoutes(),
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  })
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => !record.meta.openPage)) {
      Auth.getCurrentUser()
        .then(r => {
          let userData: User = r.data
          let isAuthenticated = false
          if (userData.username) {
            let userRoles = userData.roles.map(r => r.replace(new RegExp('ROLE_', 'g'), '').toLowerCase())
            let userRolesString = userRoles.join(',')
            isAuthenticated = (userData.username && userData.username !== undefined && userData.username !== null && userData.username !== '')
            localStorage.setItem('username', userData.username)
            localStorage.setItem('email', userData.email)
            localStorage.setItem('userid', userData.id)
            localStorage.setItem('region', (userData.region) ? userData.region.id : '')
            localStorage.setItem('user_role', userRolesString)
            if (userData.testProjects && Array.isArray(userData.testProjects) && userData.testProjects.length > 0) {
              localStorage.setItem('project.id', JSON.stringify(userData.testProjects[0].testProject.id))
              localStorage.setItem('project.isPrivate', JSON.stringify(userData.testProjects[0].testProject.isPrivate))
              localStorage.setItem('project.disableCourses', JSON.stringify(userData.testProjects[0].testProject.disableCourses))
              localStorage.setItem('project.hasSplash', JSON.stringify(userData.testProjects[0].testProject.hasSplash))
              localStorage.setItem('project.tests', JSON.stringify(userData.testProjects[0].testProject.tests))
            }
            let fio = ''
            if (userData.surname) {
              fio = (userData.surname + ' ')
            }
            if (userData.name) {
              fio += (userData.name + ' ')
            }
            if (userData.secondname) {
              fio += (userData.secondname)
            }
            if (fio === '') {
              fio = userData.username
            }
            localStorage.setItem('fio', fio)
          }
          if (!isAuthenticated) {
            next({
              path: '/enter'
            })
          } else {
            next()
          }
        })
        .catch(e => {
          if (e.response && e.response.data) {
            console.error(e.response)
            // todo log
          }
          next({
            path: '/enter'
          })
        })
    } else {
      next()
    }
  })
  return router
}
