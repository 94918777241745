import { Component, Vue } from 'vue-property-decorator'
import Copyright from 'component/copyright'

import API from 'api'
import TestProject from 'model/TestProject'
import TestRestrictionRole from 'model/TestRestrictionRole'

@Component({
  template: require('template/index.html'),
  components: { Copyright }
})
export default class Index extends Vue {
  pageTitle: string = 'Личный кабинет'
  private testProjects: TestProject[] = []
  private testRestrictionRoles: TestRestrictionRole[] = []

  mounted () {
    API.getCurrentTestProjects().then(r => this.testProjects = r.data)
    API.getCurrentTestRestrictionRoles().then(r => this.testRestrictionRoles = r.data)
  }

  get projectDisableCourses () {
    return Vue.localStorage.get('project.disableCourses') === 'true'
  }

  get roleName () {
    return Vue.localStorage.get('user_role')
  }

  get user () {
    return localStorage.getItem('fio')
  }
}
