import { Component, Vue } from 'vue-property-decorator'
import User from 'model/User'
import CountryRegion from 'model/CountryRegion'
import FederalDistrict from 'model/FederalDistrict'
import Subject from 'model/Subject'

@Component({
  template: require('template/profile.html'),
  components: {}
})
export default class Profile extends Vue {
  pageTitle: string = 'Мой профиль'
  user: User = new User({
    login: 'admin'
  })
  errors: string[] = []
  success: boolean = false

  regions: CountryRegion[] = []
  federalDistricts: FederalDistrict[] = []
  subjects: Subject[] = []

  mounted () {
    const self = this
    CountryRegion.getAll('Region')
      .then(r => this.regions = r.data as CountryRegion[])
      .catch(console.error)

    FederalDistrict.getAll('FederalDistrict')
      .then(r => this.federalDistricts = r.data as FederalDistrict[])
      .catch(console.error)

    Subject.getAll('Subject')
      .then(r => this.subjects = r.data as Subject[])
      .catch(console.error)

    User.getCurrent()
      .then(
        r => {
          self.user = new User(r.data)
          self.user.formatDates()
        }
      )
      .catch(console.error)
  }

  saveProfile () {
    let result = this.user.saveCurrent()
    let self = this
    result.then(
      r => {
        self.errors = r.data.errors
        self.success = r.data.success
        window.scrollTo(0, 0)

      })
      .catch(e => console.error(e))
  }
}
