import API from 'api'
import { AxiosPromise } from 'axios'

export default class BaseModel {

  public id: string

  public static getEntityName<T extends BaseModel> (this: {new(): T}): string {
    return this.name
  }

  public static getAll<T extends BaseModel> (name: string): AxiosPromise<T[]> {
    return API.getAll(name)
  }

  public static getBy<T extends BaseModel> (name: string, filteringClass: string, filteringLabel: string, filterObj: any, orderObj: any | null): AxiosPromise<T[]> {
    return API.getBy(name, filteringClass, filteringLabel, filterObj, orderObj)
  }

  public static getHeaders<T extends BaseModel> (name: string, categoryId: string): AxiosPromise<T[]> {
    return API.getHeaders(name, categoryId)
  }

  public static get<T extends BaseModel> (name: string, id: string): AxiosPromise<T> {
    return API.get(name, id)
  }

  public static getWithSerializationLevel<T extends BaseModel> (name: string, id: string, serializationLevel): AxiosPromise<T> {
    return API.get(name, id, serializationLevel)
  }

  public static save<T extends BaseModel> (entityName: string, instance: T): AxiosPromise<T> {
    return API.save(entityName, instance.id, instance)
  }

  public static remove<T extends BaseModel> (entityName: string, id: string): AxiosPromise<any> {
    return API.remove(entityName, id)
  }
}
