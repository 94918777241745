import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage)

import Test from 'model/TestData'
import TestCategory from 'model/TestCategory'
import CountryRegion from 'model/CountryRegion'
import CodifierElement from 'model/CodifierElement'

import a, { AxiosResponse } from 'axios'
import API from 'api'
import RegionDistrict from 'model/RegionDistrict'
import Subject from 'model/Subject'

import vSelect from 'vue-select'

export class FilterPeriodAnalytic {
  from: string = ''
  to: string = ''
}

export class FilterAnalytic {
  public static FILTER_NAME: string = 'analyticFilter'
  public static COMPARING_FILTER_NAME: string = 'analyticFilterComparing'

  region: CountryRegion = null
  regionDistrict: any = null
  byPeriod: boolean = false
  period: FilterPeriodAnalytic = new FilterPeriodAnalytic()
  diagnostic: string = null
  orgName: string = null
  categoryId: string = null
  codifierElementId: string = null
  subject: Subject = null
  testId: string = null
  subjectId: string = null
  regionId: string = null
  regionDistrictId: string = null
  compareMode: boolean = false
  limit: number = 300
  offset: number = 0
}

@Component({
  template: require('template/_filter-analitica.html'),
  components: { vSelect }
})
export default class FilterAnalitica extends Vue {

  @Prop([Boolean])
  showCodifiers: boolean

  @Prop([Boolean])
  comparingFilter: boolean

  @Prop([Boolean])
  hideCountryRegions: boolean

  @Prop([Boolean])
  hideRegionDistricts: boolean

  @Prop([Boolean])
  hideOrganizations: boolean

  @Prop([Boolean])
  hideTeacherSubjects: boolean

  @Prop([Boolean])
  hidePeriod: boolean

  compareWindowIsOpened: boolean = false

  startDiagnosticSelected: any = null
  finishDiagnosticSelected: any = null

  regions: CountryRegion[] = []
  districts: RegionDistrict[] = []
  categories: TestCategory[] = []
  subjects: Subject[] = []
  tests: Test[] = []
  codifierElements: CodifierElement[] = []
  schools: string[] = []

  filter: FilterAnalytic = new FilterAnalytic()

  public filteredCategories: TestCategory[] = []
  public filteredTests: Test[] = []

  private static isDemoUser (username) {
    return username.substr(0, 4) === 'demo'
  }

  mounted () {
    if (!this.comparingFilter) {
      this.filter = JSON.parse(Vue.localStorage.get(FilterAnalytic.FILTER_NAME, JSON.stringify(new FilterAnalytic())))
    } else {
      this.filter = JSON.parse(Vue.localStorage.get(FilterAnalytic.COMPARING_FILTER_NAME, JSON.stringify(new FilterAnalytic())))
    }
    if (this.filter == null) {
      this.filter = new FilterAnalytic()
    }

    Promise.all([
      this.loadCountryRegions().then(() => this.loadRegionDistricts()),
      this.loadTestCategories(),
      this.loadTests(),
      this.loadSubjects(),
      this.loadSchools()
    ]).then(this.finalizeFilters).catch(console.error)
  }

  finalizeFilters () {
    if (this.categories) {
      this.filteredCategories = this.categories
    }
    if (this.tests) {
      this.filteredTests = (this.filter && this.filter.categoryId) ? this.tests.filter(t => t.category.id === this.filter.categoryId) : this.tests
    }
    this.apply()
  }

  apply () {
    this.filter.regionId = (this.filter && this.filter.region && this.filter.region.id) ? this.filter.region.id : null
    this.filter.regionDistrictId = (this.filter && this.filter.regionDistrict && this.filter.regionDistrict.id) ? this.filter.regionDistrict.id : null
    this.filter.subjectId = (this.filter && this.filter.subject && this.filter.subject.id) ? this.filter.subject.id : null
    Vue.localStorage.set(!this.comparingFilter ? FilterAnalytic.FILTER_NAME : FilterAnalytic.COMPARING_FILTER_NAME, JSON.stringify(this.filter))
    this.$emit('filter', this.hideExcessFiltersIfNeeded(this.filter))
  }

  clear () {
    const username = localStorage.getItem('username')
    let region = null
    let regionId = null
    if (FilterAnalitica.isDemoUser(username)) {
      region = this.filter.region
      regionId = this.filter.regionId
    }
    this.filter = new FilterAnalytic()
    if (FilterAnalitica.isDemoUser(username)) {
      this.filter.region = region
      this.filter.regionId = regionId
    }
    this.apply()
  }

  onChange (val, oldVal) {
    this.$emit('input', this.filter)
  }

  onPeriodEnabledChange (val, oldVal) {
    if (!this.filter.byPeriod) {
      this.filter.period = new FilterPeriodAnalytic()
    }
    this.onChange(val, oldVal)
  }

  onRegionChange (val, oldVal) {
    this.clearSelectedRegionDistrict()
    this.loadRegionDistricts()
    this.loadSchools()
    if (this.filter.region == null) {
      this.filter.regionId = null
      this.districts = []
      this.clearSelectedRegionDistrict()
    }
    this.onChange(val, oldVal)
  }

  openCompareWindow () {
    this.compareWindowIsOpened = true
  }

  closeCompareWindow () {
    this.compareWindowIsOpened = false
  }

  addCompareFilterSet () {
    if (!this.filter.compareMode) {
      this.filter.compareMode = true
      this.apply()
      this.$emit('compare', this.filter)
    } else {
      this.filter.compareMode = false
      this.apply()
    }
  }

  doCompare () {
    this.filter.compareMode = true
    this.closeCompareWindow()
    this.apply()
  }

  stopCompare () {
    this.filter.compareMode = false
    this.closeCompareWindow()
    this.startDiagnosticSelected = null
    this.finishDiagnosticSelected = null
  }

  onCategoryChange (val, oldVal) {
    this.filterTestsByCategory()
    this.onChange(val, oldVal)
  }

  onTestChange (val, oldVal) {
    if (this.showCodifiers && this.filter.testId) {
      a.get(API.api + '/analytic/getCodifiersInTest/' + this.filter.testId)
        .then(r => this.codifierElements = r.data as CodifierElement[])
        .catch(console.error)
      this.onChange(val, oldVal)
    }
  }

  private loadCountryRegions (): Promise<void> {
    const username = localStorage.getItem('username')
    const region = localStorage.getItem('region')
    return API.filters.getRegions()
      .then(r => {
        this.regions = r.data as CountryRegion[]
        if (FilterAnalitica.isDemoUser(username) && region) {
          let filterRegion = this.regions.find(r => r.id === region)
          if (filterRegion) {
            this.filter.region = filterRegion
            this.filter.regionId = filterRegion.id
            this.regions.filter(r => r.id !== filterRegion.id).forEach(r => r.hidden = true)
          }
        }
      })
  }

  private loadRegionDistricts (): Promise<RegionDistrict[]> {
    if (this.filter && this.filter.region) {
      return RegionDistrict.getBy('RegionDistrict', 'Region', 'region', this.filter.region, { name: 'ASC' })
        .then(r => this.districts = r.data as RegionDistrict[])
    }
  }

  private loadTestCategories (): Promise<void> {
    return API.filters.getTestCategories()
      .then(
        result => {
          this.categories = result.data as TestCategory[]
          this.categories.forEach(c => Vue.set(c, 'tests', []))
        }
      )
  }

  private loadTests (): Promise<void> {
    return API.filters.getTests()
      .then(
        r => {
          this.tests = r.data as Test[]
          this.filteredTests = this.tests
          this.filterTestsByCategory()
        }
      )
  }

  private loadSchools (): Promise<string[]> {
    return API.filters.getOrgNames(this.filter.region).then(r => this.schools = r.data)
  }

  private loadSubjects (): Promise<Subject[]> {
    return Subject.getAll('Subject')
      .then(r => this.subjects = r.data as Subject[])
  }

  private filterTestsByCategory () {
    if (this.tests) {
      if (!Array.isArray(this.tests)) {
        this.filteredTests = []
        for (let prop in (this.tests as any)) {
          if ((this.tests as any).hasOwnProperty(prop)) {
            let t = this.tests[prop] as Test
            if (!this.filter || !this.filter.categoryId || (t.category.id === this.filter.categoryId)) {
              this.filteredTests.push(this.tests[prop])
            }
          }
        }
      } else {
        this.filteredTests = this.tests.filter(t => !this.filter || !this.filter.categoryId || (t.category.id === this.filter.categoryId))
      }
      console.log('ft', this.filteredTests)
    }
  }

  private clearSelectedRegionDistrict () {
    this.filter.regionDistrict = null
    this.filter.regionDistrictId = null
  }

  private hideExcessFiltersIfNeeded (filter: FilterAnalytic): FilterAnalytic {
    let actualFilter = Object.assign({}, filter)
    if (filter) {
      if (this.hideRegionDistricts) {
        actualFilter.regionDistrict = null
        actualFilter.regionDistrictId = null
      }
      if (this.hidePeriod) {
        actualFilter.byPeriod = false
        actualFilter.period = new FilterPeriodAnalytic()
      }
      if (this.hideCountryRegions) {
        actualFilter.region = null
        actualFilter.regionId = null
      }
      if (this.hideOrganizations) {
        actualFilter.orgName = null
      }

      if (this.hideTeacherSubjects) {
        actualFilter.subject = null
        actualFilter.subjectId = null
      }
    }
    return actualFilter
  }
}
