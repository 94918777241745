
import {Component, Vue} from 'vue-property-decorator'
import {FilterAnalytic} from 'part/filter-analitica'
import MainMenu from 'component/main-menu'
import Splash from 'component/splash.vue'

import MainMenuData from 'data/main-menu'

@Component({
  components: { MainMenu, Splash }
})
export default class Site extends Vue {

  public menuIsVisible: boolean = true

  mainHeaderVisible: boolean = true
  serviceHeaderVisible: boolean = false
  serviceHeaderText: string = ''

  public mainMenu = MainMenuData

  get fio () {
    return localStorage.getItem('fio')
  }

  get projectDisableCourses () {
    return Vue.localStorage.get('project.disableCourses') === 'true'
  }

  get projectShowSplash() {
    return Vue.localStorage.get('project.hasSplash') === 'true'
  }

  toggleMenu () {
    this.menuIsVisible = !this.menuIsVisible
  }

  logout () {
    Vue.localStorage.set('oauth', null)
    Vue.localStorage.set('username', null)
    Vue.localStorage.set('email', null)
    Vue.localStorage.set('project.id', null)
    Vue.localStorage.set('project.tests', null)
    Vue.localStorage.set('project.isPrivate', null)
    Vue.localStorage.set('project.disableCourses', null)
    Vue.localStorage.set('project.hasSplash', null)
    Vue.localStorage.set('email', null)
    Vue.localStorage.set('user_role', null)
    Vue.localStorage.set(FilterAnalytic.FILTER_NAME, null)
    Vue.localStorage.set(FilterAnalytic.COMPARING_FILTER_NAME, null)
    this.$router.push('/enter')
  }

  changeSiteLayoutToTestTrack (eventParams: {testCode: string, testName: string}) {
    this.mainHeaderVisible = false
    this.serviceHeaderText = eventParams.testCode + '. ' + eventParams.testName
    this.serviceHeaderVisible = true
  }

  restoreNormalSiteLayout () {
    this.mainHeaderVisible = true
    this.serviceHeaderText = ''
    this.serviceHeaderVisible = false
  }
}
