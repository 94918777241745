var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm.mainHeaderVisible
      ? _c("header", { staticClass: "header" }, [
          _c("nav", { staticClass: "navbar" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c(
                "div",
                {
                  staticClass:
                    "navbar-holder d-flex align-items-center justify-content-between"
                },
                [
                  _c(
                    "div",
                    { staticClass: "navbar-header" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "active",
                          staticStyle: { cursor: "pointer" },
                          attrs: { id: "toggle-btn" },
                          on: { click: _vm.toggleMenu }
                        },
                        [_c("i", { staticClass: "fa fa-bars" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-brand",
                          attrs: { to: "/index" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "brand-text brand-big _hidden-sm-down"
                            },
                            [_c("span", [_vm._v("СДПК")]), _c("strong")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "nav-menu list-unstyled _d-flex _flex-md-row _flex-sm-col _align-items-md-center nav _navbar-nav _pull-xs-right collapse",
                      attrs: { id: "mainMenu" }
                    },
                    [
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              href: "#",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            }
                          },
                          [_vm._v("О проекте ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "notifications" }
                          },
                          [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: "/about" }
                                  },
                                  [_vm._v("Обзор")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              href: "#",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            }
                          },
                          [_vm._v("Диагностика ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "notifications" }
                          },
                          [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: "/overview" }
                                  },
                                  [_vm._v("Обзор")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: "/tests" }
                                  },
                                  [_vm._v("Каталог тестов")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      !_vm.projectDisableCourses
                        ? _c("li", { staticClass: "nav-item dropdown" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  href: "#",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false"
                                }
                              },
                              [_vm._v("Обучение ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-menu",
                                attrs: { "aria-labelledby": "notifications" }
                              },
                              [
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { to: "/overview/courses" }
                                      },
                                      [_vm._v("Обзор")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { to: "/courses" }
                                      },
                                      [_vm._v("Каталог курсов")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { to: "/courses/my" }
                                      },
                                      [_vm._v("Моё обучение")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: "/contacts" }
                            },
                            [_vm._v("Контакты")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.serviceHeaderVisible
      ? _c("header", { attrs: { id: "service-header" } }, [
          _vm._v(_vm._s(_vm.serviceHeaderText))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-content d-flex align-items-stretch" },
      [
        _vm.menuIsVisible
          ? _c("nav", { staticClass: "side-navbar" }, [
              _c(
                "div",
                { staticClass: "side-navbar-inner" },
                [
                  _c(
                    "div",
                    { staticClass: "sidebar-header d-flex align-items-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "avatar" },
                        [
                          _c("router-link", { attrs: { to: "/profile" } }, [
                            _c("img", {
                              staticClass: "img-fluid rounded-circle admin",
                              attrs: {
                                src: "/img/default-profile.png",
                                alt: _vm.fio,
                                title: "fio"
                              }
                            })
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [
                        _c(
                          "h2",
                          { staticClass: "name admin" },
                          [
                            _c("router-link", { attrs: { to: "#" } }, [
                              _vm._v(_vm._s(_vm.fio))
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("main-menu", {
                    attrs: { structure: _vm.mainMenu },
                    on: { logout: _vm.logout }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-view",
          {
            attrs: { isMenuVisible: _vm.menuIsVisible },
            on: {
              "test-started": _vm.changeSiteLayoutToTestTrack,
              "test-finished": _vm.restoreNormalSiteLayout
            }
          },
          [_vm._v("loading...")]
        ),
        _vm._v(" "),
        _vm.projectShowSplash ? _c("splash") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "navbar-toggler d-block d-md-none btn-sm  _pull-xs-right _active",
        attrs: {
          href: "#",
          "data-toggle": "collapse",
          "data-target": "#mainMenu",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "_icon fa fa-ellipsis-v" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }