import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  template: require('template/_result-progress-bar.html')

})
export default class ResultProgressBar extends Vue {
  @Prop()
  percent: number

  @Prop([Number])
  width: number

  @Prop([Number])
  height: number

  @Prop([Number])
  threshold: number

  minWidth: number = 200
  minHeight: number = 20
  miniViewThreshold: number = 28

  mounted () {
    if (this.width) {
      this.minWidth = this.width
    }
    if (this.height) {
      this.minHeight = this.height
    }
    if (this.threshold) {
      this.miniViewThreshold = this.threshold
    }
  }

  get roundedPercent (): number {
    return Math.round(this.percent)
  }
}
