import { Component, Vue } from 'vue-property-decorator'
import Copyright from 'component/copyright'

@Component({
  template: require('template/register.html'),
  components: { Copyright }
})
export default class Register extends Vue {
  pageTitle: string = 'Регистрация'
}
