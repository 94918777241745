import { Component, Prop, Vue } from 'vue-property-decorator'
import MainMenuStructureElement from 'model/local/main-menu-structure-element'

@Component({
  template: require('./main-menu-element.html'),
  name: 'main-menu-element'
})
export default class MainMenuElement extends Vue {
  @Prop([MainMenuStructureElement])
  el: MainMenuStructureElement
}
