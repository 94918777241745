import { Component, Vue } from 'vue-property-decorator'
import Copyright from 'component/copyright'
import applyConverters from 'axios-case-converter'
import axios from 'axios'
import PrettyCheckbox from 'pretty-checkbox-vue'
import 'pretty-checkbox/src/pretty-checkbox.scss'
import API from '../api'

Vue.use(PrettyCheckbox)

export class OAuth2Response {
  accessToken: string = null
  expiresIn: number = 0
  refreshToken: string = null
  scope: string = null
  tokenType: string = null
}

export class OAuth2Error {
  error: string = null
  errorDescription: string = null
}

@Component({
  template: require('template/login.html'),
  components: { Copyright }
})
export default class Login extends Vue {
  public email: string = ''
  public password: string = ''

  public errorMessage: string = null
  public lazyOauthToken: string = null

  public isLoading: boolean = false

  host: string = (window as any).Skt.settings.host || ''
  loginRequest: string = (window as any).Skt.settings.loginRequest || ''
  clientId: string = (window as any).Skt.settings.clientId || ''
  clientSecret: string = (window as any).Skt.settings.clientSecret || '5cxrv5syi5c0c8sk0oo4ogosk4k808goccwco8c8gog0s8c8k8'

  static setAccessToken (response: OAuth2Response) {
    Vue.localStorage.set('oauth', JSON.stringify(response))
  }

  mounted () {
    Vue.localStorage.set('oauth', null)
    this.lazyOauthToken = this.$route.query['access_token'] as string
    if (this.lazyOauthToken) {
      // API.authorizeThroughNpEdu(this.lazyOauthToken).then(this.processOauthResponse)
      this.errorMessage = 'Тестирование завершено. Переход в систему больше недоступен. Большое спасибо!'
    }
  }

  login () {
    this.isLoading = true
    this.dropErrorMessage()
    const timeoutSeconds = 120
    applyConverters(axios.create({ timeout: timeoutSeconds * 1000 }))
      .get(this.host + this.loginRequest, {
        params: {
          grant_type: 'password',
          client_id: this.clientId,
          client_secret: this.clientSecret,
          username: this.email,
          password: this.password
        }
      })
      .then(this.processOauthResponse)
      .catch(e => {
        let msg = ''
        if (e.code === 'ECONNABORTED') {
          msg = 'Сервер тестирования не ответил в течение ' + timeoutSeconds + ' сек. Пожалуйста, обратитесь к администратору.'
        }
        if (e.code === 'ERR_CONNECTION_REFUSED' || e.toString().includes('Network')) {
          msg = 'Сервер тестирования недоступен. Пожалуйста, обратитесь к администратору.'
        }
        let oauthError: OAuth2Error = (e.response) ? e.response.data : new OAuth2Error()
        if (!oauthError.errorDescription && e.response && e.response.data && e.response.data.error) {
          let error = e.response.data.error
          msg = error.code + ' '
          if (Array.isArray(error.exception) && error.exception.length > 0) {
            error.exception.forEach(v => msg += v.message)
          }
          console.log(msg)
        }
        if (!msg && !e.response) {
          msg = 'Подключение невозможно. Пожалуйста, попробуйте позже.'
        }
        if (msg) {
          oauthError.errorDescription = msg
          console.log(msg)
        }
        this.processOauthError(oauthError)
        this.isLoading = false
      })
  }

  private processOauthResponse (a) {
    let oauthResponse = a.data as OAuth2Response
    Login.setAccessToken(oauthResponse)
    if (oauthResponse.accessToken) {
      this.$router.push('/')
    }
    this.isLoading = false
  }

  changedLogin () {
    this.dropErrorMessage()
  }

  changedPassword () {
    this.dropErrorMessage()
  }

  private processOauthError (e: OAuth2Error) {
    if (e.errorDescription === 'Invalid username and password combination') {
      this.errorMessage = 'Недействительный логин или пароль'
    } else {
      this.errorMessage = e.errorDescription
    }
  }

  private dropErrorMessage () {
    this.errorMessage = null
  }
}
