import User from './User'
import BaseModel from './BaseModel'
import Test from './TestData'

export default class TestAppointment extends BaseModel {
  public id: string = null
  public user: User = null
  public test: Test = null
  public created: string = null
  public author: User = null
  public finished: string = null
}
