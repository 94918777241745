import BaseModel from './BaseModel'
import a, { AxiosPromise } from 'axios'
import API from 'api/API'
import Auth from '../api/Auth'
import CountryRegion from './CountryRegion'
import FederalDistrict from './FederalDistrict'
import UserPropsStructure from './UserPropsStructure'
import TestProject from './TestProject'
import TestRestrictionRole from './TestRestrictionRole'

/**
 * Пользователь
 */

export class TestProjectUser {
  testProject: TestProject
}

export default class User extends BaseModel {
  public username: string
  public pwdOld: string
  public pwdNew: string
  public surname: string
  public name: string
  public secondname: string
  public email: string
  public phone: string
  public snils: string
  public federalDistrict: FederalDistrict
  public region: CountryRegion
  public birthdate: string
  public sex: string
  public props: UserPropsStructure = new UserPropsStructure()
  public categories: string
  public promocodes: string
  public testProjects: TestProjectUser[]
  public testRestrictionRoles: TestRestrictionRole[]
  public roles: string[] = []

  constructor (params?: any) {
    super()
    if (params) {
      this.username = params.username
      this.surname = params.surname
      this.name = params.name
      this.secondname = params.secondname
      this.email = params.email
      this.phone = params.phone
      this.snils = params.snils
      this.birthdate = params.birthdate
      this.sex = params.sex
      this.props = params.props
      this.categories = params.categories
      this.promocodes = params.promocodes
      this.region = params.region
      this.props = (params.props && !(Array.isArray(params.props) && params.props.length === 0)) ? Object.assign(new UserPropsStructure(),params.props) : new UserPropsStructure()
      if (typeof this.props.schoolLevels === 'boolean') {
        this.props.schoolLevels = []
      }
      this.federalDistrict = (params.region) ? params.region.federalDistrict : null
    }
  }

  /**
   * Запросить у сервера текущего пользователя
   * @returns {AxiosPromise<any>}
   */
  public static getCurrent () {
    return Auth.getCurrentUser()
  }

  public formatDates () {
    let moment = require('moment')
    this.birthdate = moment(this.birthdate).format('YYYY-MM-DD')
  }

  public unformatDates () {
    let moment = require('moment')
    this.birthdate = moment(this.birthdate).toISOString(true)
  }

  /**
   * Сохранить текущего пользователя
   * @returns {AxiosPromise<User>}
   */
  public saveCurrent () {
    this.unformatDates()
    let response
    let descriptor = a.post(API.api + '/save/currentUser', this)
    this.formatDates()
    return descriptor
  }
}
