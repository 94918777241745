export default class UserPropsStructure {
  public locationType: number = 0
  public locationName: string = null
  public orgName: string = null
  public municipality: string = null
  public duty: string = null
  public department: string = null
  public schoolLevels: number[] = []
  public mainSubject: string = null
  public umk: number = 0
  public yearInService: number = 0
  public yearInOrg: number = 0
  public classManager: boolean = false
  public classManagerName: string = null
  public profileEducation: number = 0
}
