import BaseModel from './BaseModel'
import FederalDistrict from './FederalDistrict'

export default class CountryRegion extends BaseModel {
  public id: string = ''
  public name: string = ''
  public code: number = 0
  public active: boolean = true
  public geoCode: string = ''
  public federalDistrict: FederalDistrict = null
  public hidden: boolean = false
}
