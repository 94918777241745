import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import TestAppointment from 'model/TestAppointment'
import Subject from 'model/Subject'

@Component({
  template: require('template/appointments.html'),
  components: {}
})
export default class Appointments extends Vue {

  public subjectsById: any = {}
  public appointments: TestAppointment[] = []

  mounted () {
    Subject.getAll('Subject').then(r => (r.data as Subject[]).forEach(s => Vue.set(this.subjectsById, s.id, s))).catch(console.error)
    TestAppointment.getAll('TestAppointment')
      .then(
        r => this.appointments = (r.data as TestAppointment[]).sort(
          (a, b) =>
            (a.user.surname + a.user.name + a.user.secondname < b.user.surname + b.user.name + b.user.secondname)
            ?
            -1
            :
            ((a.user.surname + a.user.name + a.user.secondname > b.user.surname + b.user.name + b.user.secondname) ? 1 : 0)
        )
      )
      .catch(console.error)
  }

  private formatDate (d) {
    return moment(d).format('D.MM.YYYY HH:mm')
  }
}
